import React, { useEffect } from 'react'

function Ad({ client, slot, type }) {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <ins
      className={`adsbygoogle ad ad--${type}`}
      style={{ display: 'block' }}
      data-ad-client={client}
      data-ad-slot={slot}
      data-ad-format="auto"
      data-full-width-responsive="false"
    ></ins>
  )
}

export default Ad
