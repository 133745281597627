import React from 'react'

function Clickable({ onClick, className, children }) {
  return (
    <div
      className={className}
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  )
}

export default Clickable
