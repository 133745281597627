import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import Tags from '../components/tags'

export const ArticleCellWrapper = ({ children }) => (
  <article className="article-cell">{children}</article>
)

export const ArticleCellHeader = ({ date, author }) => (
  <div className="article-cell__header">
    <span className="article-cell__pub-date">{date}</span>
    <span className="article-cell__author-name">{author}</span>
  </div>
)

export const ArticleCellLink = ({ to, children }) => (
  <Link className="article-cell__link" to={to}>{children}</Link>
)

export const ArticleCellPhoto = ({ src }) => (
  <figure className="article-cell__photo">
    <img src={src} loading="lazy" alt="" />
  </figure>
)

export const ArticleCellBody = ({ title }) => (
  <div className="article-cell__body">
    <h2 className="article-cell__title">{title}</h2>
  </div>
)

export const ArticleCellFooter = ({ tags }) => (
  <div className="article-cell__footer">
    <Tags tags={tags} />
  </div>
)

const ArticleCell = ({ title, href, date, author, eyeCatch, tags }) => (
  <ArticleCellWrapper>
    <ArticleCellLink to={href}>
      <ArticleCellPhoto src={eyeCatch} />
      <ArticleCellHeader date={date} author={author} />
      <ArticleCellBody title={title} />
    </ArticleCellLink>
    <ArticleCellFooter tags={tags} />
  </ArticleCellWrapper>
)

ArticleCell.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  eyeCatch: PropTypes.string,
  tags: PropTypes.arrayOf(
    PropTypes.string
  ),
}

export default ArticleCell
