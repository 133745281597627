import { useState, useEffect } from 'react'
import classNames from 'classnames'

if (typeof window !== 'undefined') {
  require('intersection-observer')
}

export default function useFab() {
  const baseClassName = 'fab'
  const [className, setClassName] = useState(classNames(baseClassName))

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const cn = entry.intersectionRatio === 0
          ? classNames(baseClassName, `${baseClassName}--visible`)
          : classNames(baseClassName)
        setClassName(cn)
      })
    })

    observer.observe(document.querySelector('.navbar'))

    return () => observer.disconnect()
  }, [])

  return className
}
