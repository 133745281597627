import React from 'react'
import jump from 'jump.js'
import useFab from '../hooks/fab'
import IconArrowUp from './icons/arrow-up'
import Clickable from './clickable'

export default function ScrollTop() {
  const className = useFab()
  const scrollUp = () => jump(window.scrollY * -1)

  return (
    <Clickable className={className} onClick={scrollUp}>
      <IconArrowUp />
    </Clickable>
  )
}
