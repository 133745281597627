import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

function joinPath(base, path) {
  return base.slice(-1) === '/' ? `${base}${path}` : `${base}/${path}`
}

const Prev = ({base, current}) => {
  const pagePath = current === 2 ? '' : `page-${current - 1}`
  return (
    <Link className="box-link pager__newer-link" to={joinPath(base, pagePath)}>
      <i className="box-link__icon">←</i><span>Newer</span>
    </Link>
  )
}

const Next = ({base, current}) => (
  <Link
    className="box-link pager__older-link"
    to={joinPath(base, `page-${current + 1}`)}
  >
    <span>Older</span><i className="box-link__icon">→</i>
  </Link>
)

const Counter = ({current, total}) => (
  <span className="pager__counter">
    {current} / {total}
  </span>
)

const Pager = ({base, current, total}) => {
  const isFirst = current === 1
  const isLast = current === total

  return (
    <div className="pager">
      <Counter current={current} total={total} />
      {isFirst || <Prev base={base} current={current} />}
      {isLast || <Next base={base} current={current} />}
    </div>
  )
}

Pager.propTypes = {
  base: PropTypes.string,
  current: PropTypes.number,
  total: PropTypes.number,
}

export default Pager
